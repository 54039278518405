<template>
  <div>
    
          
    <div class="card">

      <div class="card-body">
        
        <tables-antd-custom-filter />
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import TablesAntdCustomFilter from "./custom-filter/index";

export default {
  components: {
    TablesAntdCustomFilter,
  },
};
</script>
